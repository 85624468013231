$( document ).on('turbolinks:load', function() {
  // nav burger menu
  $( ".nav-burger-open" ).click(function() {
    $(".nav-burger-menu").removeClass("d-none");
    $(".nav-burger-menu").addClass("d-flex");
  });
  $(".nav-burger-close").click(function() {
    $(".nav-burger-menu").removeClass("d-flex");
    $(".nav-burger-menu").addClass("d-none");
  });

  // partner page boxes
  var partnerBoxWidth = $(".partner-box").width();
  $(".partner-box").height(partnerBoxWidth);

  // delta slider
  $('.delta-slider').slick({
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 9000,
    speed: 600,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: false
  });

});